:root {
  --color-primary: #6381f3;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .text-gradient {
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

@layer utilities {
  @variants responsive {
    .play-substract {
      left: calc(50% - 3rem);
    }

    .play-add {
      left: calc(50% + 3rem);
    }
  }
}

.center {
  @apply flex;
  @apply items-center;
  @apply justify-center;
}

.clickable {
  @apply transition-all;
  @apply duration-300;
  @apply ease-in-out;
  @apply cursor-pointer;
}

.clickable-opacity {
  @apply hover:opacity-70;
  @apply transition-opacity;
  @apply duration-300;
  @apply ease-in-out;
  @apply cursor-pointer;
}

.tooltip-custom {
  border-radius: 8px !important;
  border: 1px solid #e6e7fd !important;
  background: white !important;
  color: #7072a2 !important;
  opacity: 1 !important;
  box-shadow: 0 2px 8px rgba(41, 41, 64, 0.1);
  margin-left: 10px !important;
  padding: 5px !important;
}

/* Changelog */

#HW_badge_cont {
  @apply absolute;
}

#HW_badge_cont #HW_badge {
  @apply bg-primary;
}

#HW_badge_cont .HW_badge.HW_visible {
  transform: scale(1.2);
}

#HW_badge_cont #HW_badge.HW_softHidden {
  @apply bg-transparent;
}

/* Is Premium  */

.is-premium-wrapper {
  position: relative;
  width: 100%;
}

.is-premium-wrapper .is-premium {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  cursor: pointer;
  color: black;
  font-weight: bold;
}

.is-premium-children {
  width: 100%;
  pointer-events: none;
  transition: all 0.3s ease-out;
}

.blurred {
  filter: blur(2px);
}

.rc-md-editor textarea:focus {
  border: 1px solid var(--color-primary) !important;
  box-shadow: none !important;
}

.scroll-mask {
  mask-image: linear-gradient(
    0deg,
    transparent,
    #000 20px,
    #000 calc(100% - 20px),
    transparent
  );
  -webkit-mask-image: linear-gradient(
    0deg,
    transparent,
    #000 20px,
    #000 calc(100% - 20px),
    transparent
  );
}

/* Annotations panel */

.annotations-list {
  overflow: auto;
  height: calc(100vh - 140px);
}

.annotations-list.has-powered-by {
  height: calc(100vh - 186px);
}

/* Crisp */

.crisp-client .cc-unoo {
  @apply scale-90;
  @apply lg:scale-100;
}

/* Editor */

.rc-md-editor textarea,
.custom-html-style * {
  font-size: 16px !important;
}

.custom-html-style ul,
.custom-html-style ol {
  display: block;
  list-style: disc outside none;
  margin: 1em 0;
  padding: 0 0 0 40px;
}

.custom-html-style ol {
  list-style-type: decimal;
}

#__next {
  @apply min-h-screen;
}

.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

/* Spinner */

.lds-ring {
  display: inline-block;
  position: relative;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  border: 2px solid rgb(75, 85, 99);
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: rgb(75, 85, 99) transparent transparent transparent;
  width: 100%;
  height: 100%;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* React Select */

.react-select input {
  box-shadow: none !important;
}

.line-clamp-2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
